import { FacebookPixelsHead } from './FacebookTracker';
import { GA4Head } from './GA4';
import { GTMHead } from './GTM';
import { HubspotHead } from './Hubspot';
import type { ClientConfig } from '@archipro-website/config';
import { RescueMetricsHead } from './RescueMetrics';
import { PinterestTrackerHead } from './PinterestTracker';

export const HeadPixels = ({
    gtmId,
    ga4TrackingId,
    facebookPixelArchiproId,
    facebookPixelClientId,
    hubspotTrackingId,
    rescueMetricsUrl,
    pinterestTagArchiproId,
}: ClientConfig['pixels']) => {
    // Uncomment to conditionally render pixels based on environment
    // const { env } = useRootData();
    // const isProd = env.stage === 'Production';

    return (
        <>
            {rescueMetricsUrl && <RescueMetricsHead url={rescueMetricsUrl} />}
            <GTMHead id={gtmId} />
            <GA4Head id={ga4TrackingId} />
            <FacebookPixelsHead
                pixel={facebookPixelArchiproId}
                pixelClient={facebookPixelClientId}
            />
            <HubspotHead hubspotTrackingId={hubspotTrackingId} />
            <PinterestTrackerHead pixel={pinterestTagArchiproId} />
        </>
    );
};
