import type { BaseThemePrepared } from '@archipro-design/aria';

export const processTheme = (
    theme: BaseThemePrepared,
    shouldReplace: boolean
) => {
    if (!shouldReplace) return theme;
    return replaceBreakpoints(theme);
};

const OVERRIDE_BREAKPOINT = (val: number) =>
    `@media screen and (min-width: ${val}px)`;

/*
    remove break points so scoped css will be apply on shrank window
*/
const replaceBreakpoints = (theme: BaseThemePrepared) => {
    // this should include all rules without messing with media queries priority

    return {
        ...theme,
        screen: {
            ...theme.screen,
            tablet: OVERRIDE_BREAKPOINT(1),
            laptop: OVERRIDE_BREAKPOINT(2),
            desktop: OVERRIDE_BREAKPOINT(3),
            min: {
                ...theme.screen.min,
                tablet: OVERRIDE_BREAKPOINT(1),
                laptop: OVERRIDE_BREAKPOINT(2),
                desktop: OVERRIDE_BREAKPOINT(3),
            },
        },
    };
};
