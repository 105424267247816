import { Tracker } from '../../types';
import { TrackedGuest } from '../archiproTracker';
import { professionalCategoryToHubspotMapping } from './utils';

export type IPushParams = [string, (string | object)?];

declare global {
    interface Window {
        _hsq: { push: (callParam: IPushParams) => void };
    }
}

interface TrackedMember {
    ID: number;
    __typename: 'Me';
    FirstName: string;
    LastName: string;
    Company: string;
    Email: string;
    Phone: string;
    ProfessionalType: string;
    ArchiproProfilePage: string;
    Website: string;
    IsArchiproStaff: boolean;
}

type TrackedUser = TrackedMember | TrackedGuest;

export function createHubspotTracker(user: TrackedUser): Tracker {
    if (user.__typename === 'Me' && !user.IsArchiproStaff) {
        const _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
            'identify',
            {
                firstname: user.FirstName,
                lastname: user.LastName,
                company: user.Company,
                email: user.Email,
                phone: user.Phone,
                industry_category:
                    professionalCategoryToHubspotMapping[
                        user.ProfessionalType as keyof typeof professionalCategoryToHubspotMapping
                    ],
                archipro_profile_page:
                    user.ArchiproProfilePage !== ''
                        ? 'https://archipro.co.nz/' + user.ArchiproProfilePage
                        : '',
                website: user.Website,
            },
        ]);
    }

    return {
        name: 'hubspotTracker',
        // eslint-disable-next-line @typescript-eslint/require-await
        async log(event, opts) {
            if (event !== 'PageView') return;

            const url = opts.url;

            const _hsq = window._hsq || [];
            _hsq.push(['setPath', url.pathname]);
            // Track the page view for the new page
            _hsq.push(['trackPageView']);
            window._hsq = _hsq;
        },
        release() {
            // do nothing
        },
    };
}
