import { ScrollRestoration } from '@remix-run/react';
import { useLocation } from '@remix-run/react';

/**
 * A temporary solution until remix releases a version with react router 6.4.
 * Which we can then just use <Link preventScrollReset /> to do what this is doing here.
 *
 * Solution source: https://discord.com/channels/770287896669978684/1020648336304394280/1020786888568750130
 *
 */
const ConditionalScrollRestoration = () => {
    const location = useLocation();
    if (
        location.state != null &&
        typeof location.state === 'object' &&
        (location.state as { scroll: boolean }).scroll === false
    ) {
        return null;
    }

    return <ScrollRestoration />;
};

export default ConditionalScrollRestoration;
