import RootPage from '@modules/root/page/RootPage';
import { json, type ShouldRevalidateFunction } from '@remix-run/react';
import { getThemeMetaTags } from '@modules/seo';
import type {
    DataFunctionArgs,
    HeadersFunction,
    MetaFunction,
    SerializeFrom,
} from '@remix-run/node';
import customiOS from '~/styles/customiOS.css?url';
import customSafari from '~/styles/customSafari.css?url';
import desktopOnly from '~/styles/desktopOnly.css?url';
import matrixStyle from '@modules/error/component/matrix-rain/matrix-rain.css?url';

// Root never updates - It's set per document request.
export const shouldRevalidate: ShouldRevalidateFunction = () => {
    return false;
};

export const meta: MetaFunction<typeof loader> = (props) => {
    const data = props?.data;
    const stage = data?.env?.stage;
    const device = data?.device;
    const links = [];

    if (device?.type === 'desktop') {
        links.push({
            tagName: 'link',
            rel: 'stylesheet',
            href: desktopOnly,
        });
    }
    if (device?.type !== 'desktop' && device?.os.isIOS) {
        links.push({
            tagName: 'link',
            rel: 'stylesheet',
            href: customiOS,
        });
    }
    if (device?.type === 'desktop' && device?.browser.isSafari) {
        links.push({
            tagName: 'link',
            rel: 'stylesheet',
            href: customSafari,
        });
    }

    if (data?.env.stage !== 'Production') {
        links.push({
            tagName: 'link',
            rel: 'stylesheet',
            href: matrixStyle,
        });
    }

    const themeMetaTags = getThemeMetaTags(props);
    return [
        ...(themeMetaTags ? themeMetaTags : []),
        ...(stage !== 'Production'
            ? [{ name: 'robots', content: 'noindex' }]
            : []),
        ...links,
    ];
};

export const headers: HeadersFunction = () => {
    return {
        // Set cache-control header to prevent from caching document requests
        'Cache-Control': 'no-store',
    };
};

export type RootData = SerializeFrom<typeof loader>;

/**
 * error free loader. Proxy the context to the client.
 */
export const loader = async ({ context }: DataFunctionArgs) => {
    const { env, config, localisation, device, requestId, appDisplayMode } =
        context;

    const [user, growthbookContext] = await Promise.all([
        context.getUser(),
        context.getABTests(),
    ]);

    const data = {
        env,
        config,
        localisation,
        device,
        requestId,
        appDisplayMode,
        user,
        growthbookContext,
    };

    return json(data);
};

export { links, ErrorBoundary } from '@modules/root/page/RootPage';
export default RootPage;
