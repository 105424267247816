import { Tracker } from '../../types';
import { getPinterestEvent } from './utils';

declare function pintrk(
    command: 'track',
    eventName: string,
    data?: Record<string, unknown>
): void;

export function createPinterestTracker(): Tracker {
    return {
        name: 'pinterestTracker',
        // eslint-disable-next-line @typescript-eslint/require-await
        async log(event, opts) {
            const pinterestEventData = getPinterestEvent(event);
            if (pinterestEventData) {
                const { eventName } = pinterestEventData;
                console.log(
                    '%cPinterestEvent: ' + eventName,
                    'background: #222; color: #bada55'
                );

                if (opts.data) {
                    pintrk('track', eventName, opts.data);
                } else {
                    pintrk('track', eventName);
                }
            }
        },
        release() {
            // do nothing
        },
    };
}
