/// <reference types="@types/facebook-pixel" />
import { Tracker } from '../../types';
import { getFacebookEvent } from './utils';

// Left over from legacy code
declare global {
    interface Window {
        fbLoaded?: {
            promise: Promise<void>;
        };
    }
}

export function createFacebookTracker(): Tracker {
    return {
        name: 'facebookTracker',
        async log(event, opts) {
            await window.fbLoaded?.promise;

            const facebookEventData = getFacebookEvent(event);
            if (facebookEventData) {
                const { eventName, type } = facebookEventData;
                console.log(
                    '%cFacebookEvent: ' + eventName + ' (' + type + ')',
                    'background: #222; color: #bada55'
                );

                const endpoint = type === 'standard' ? 'track' : 'trackCustom';

                // @todo map the customData object against valid Facebook parameters.
                if (opts.data) {
                    fbq(endpoint, eventName, opts.data);
                } else {
                    fbq(endpoint, eventName);
                }
            }
        },
        release() {
            // do nothing
        },
    };
}
