import { MutableRefObject } from 'node_modules/@types/react';
import { Tracker } from '../types';

// A proxy tracker to switch tracker instances and avoid refreshing react state.
export function proxyTracker(ref: MutableRefObject<Tracker>): Tracker {
    return {
        name: 'proxyTracker',
        async log(...args) {
            return ref.current.log(...args);
        },
        release() {
            if (ref.current) {
                ref.current.release();
            }
        },
    };
}
