import { useMaybeAppData } from '@modules/root';

interface GTMProps {
    id: string;
}

export const GTMHead: React.FC<GTMProps> = ({ id }) => {
    // Note: Loaded in ClientOnly, defer and async will do nothing. onLoad may also not work correctly
    // Need to feed the user id to GTM as early as possible.
    // https://developers.google.com/analytics/devguides/collection/ga4/user-id?client_type=gtm
    // We used to feed the user id in `useEffect`, but it's too late.
    // Some auto-collected events, such as page_view, will be fired before that.
    // this is also rendered in error boundaries app data might not be there
    const appData = useMaybeAppData();
    const user = appData?.user;
    // user_id for GA4. GA4 will use this property to aggregate the signed-in user numbers.
    const userID = user?.__typename === 'Me' ? user?.ID : undefined;
    // anonymous_user_id for Growthbook
    const apUserID = user?.ID
        ? user.ID * (user?.__typename === 'Me' ? 1 : -1)
        : 0;
    return (
        <>
            <script
                id="gtm-install"
                dangerouslySetInnerHTML={{
                    __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');
                    `,
                }}
            />
            <script
                defer
                id="gtm-init-user"
                dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                          userID: ${userID},
                          apUserID: ${apUserID}
                        });
                    `,
                }}
            />
        </>
    );
};

export const GTMBody: React.FC<GTMProps> = ({ id }) => {
    return (
        <noscript>
            <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${id}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
                title="gtm-tracker"
            />
        </noscript>
    );
};
