interface GA4Props {
    id: string;
}

export const GA4Head: React.FC<GA4Props> = ({ id }) => {
    // need to install and config ga4 in the same place to make it work with partytown.
    // https://stackoverflow.com/questions/75444681/how-to-make-gtag-and-gtm-work-with-partytown-in-a-react-app
    return (
        <>
            <script
                defer
                src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
            />
            <script
                id="ga-init"
                dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];

                        window.gtag = function () { dataLayer.push(arguments); };
                        gtag('js', new Date());

                        gtag('config', '${id}');
                    `,
                }}
            />
        </>
    );
};
