// This array maps the DB professionalType values into the Hubspot values
export const professionalCategoryToHubspotMapping = {
    architect: 'Architect',
    architectural: 'Architectural Designer',
    builder: 'Builder',
    contractor: 'Contractor',
    designer: 'Interior Designer',
    engineer: 'Engineer',
    industry: 'Industry Associations',
    kitchen: 'Kitchen Designer',
    landscaper: 'Landscape Architect',
    manufacturer: 'Manufacturer',
    photographer: 'Photographer',
    timber: 'Timber Joiner',
};
