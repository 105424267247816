interface HubspotProps {
    hubspotTrackingId: string;
}

export const HubspotHead: React.FC<HubspotProps> = ({ hubspotTrackingId }) => {
    return (
        <script
            id="hs-script-loader"
            defer
            src={`//js.hs-scripts.com/${hubspotTrackingId}.js`}
        />
    );
};
