import { Tracker } from '../types';
import {
    EventData,
    isIgnoredEvent,
    convertLegacyEventName,
    isLibraryPage,
    mergeAdEvents,
} from '../utils/apTrackerHelpers';
import { getPostVars } from '../utils/getPostVars';
import { APEventQueue } from '../apEventQueue';

const ARCHIPRO_LOGGING_URL_V2 = '/api/analytics/v2/collect';

export function createArchiproTrackerV2(): Tracker {
    const queue = new APEventQueue(promiseExecutor());

    return {
        name: 'archiproTrackerV2',
        // eslint-disable-next-line @typescript-eslint/require-await
        async log(event, opts) {
            if (isIgnoredEvent(event)) {
                return;
            }

            // Don't mutate the original ones, it's shared between trackers
            const url = new URL(opts.url);

            event = convertLegacyEventName(event);

            const postVars = getPostVars(event, {
                url,
                data: opts.data as Record<string, unknown>,
                previousUrl: opts.previousUrl,
            });

            postVars.libraryPage = isLibraryPage(url.pathname) ? 1 : 0;

            queue.queue(postVars);
            queue.startTimer();
        },
        release() {
            queue.flush();
        },
    };
}

function promiseExecutor(): (items: EventData[]) => Promise<void> {
    return async (items: EventData[]) => {
        try {
            const mergedItems = mergeAdEvents(items);
            await fetch(`${ARCHIPRO_LOGGING_URL_V2}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                },
                body: JSON.stringify(mergedItems),
            });
        } catch (_) {
            /**
             * these are connectivity errors, nothing to do.
             * Consider a retry perhaps
             */
            return;
        }
    };
}
