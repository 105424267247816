import type { AppDisplayMode } from '@archipro-design/aria';
import type { UIMatch } from '@remix-run/react';
import { useMatches } from '@remix-run/react';
import type { APHandle } from '../type';

export const useMockDevice = (
    searchString: string
): AppDisplayMode | undefined => {
    const matches = useMatches();
    const match = matches.find(
        (match) =>
            match.handle && match.handle.hasOwnProperty('enableMockDevice')
    ) as UIMatch<unknown, APHandle> | undefined;
    const enableMockDevice = match?.handle?.enableMockDevice === true;

    if (!enableMockDevice) {
        return undefined;
    }
    const searchParams = new URLSearchParams(searchString);
    const deviceView = searchParams.get('deviceView');
    return deviceView && deviceView === 'mobile' ? 'mobile' : 'desktop';
};
