/* eslint-disable jsx-a11y/alt-text */

interface FacebookTrackerProps {
    pixel: string;
    pixelClient: string;
}
export const FacebookPixelsHead = ({
    pixel,
    pixelClient,
}: FacebookTrackerProps): JSX.Element => {
    // Note: Loaded in ClientOnly, defer and async will do nothing. onLoad may also not work correctly
    const html = `
    if (typeof fbq === 'undefined') {
        !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.defer=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${pixel});
          fbq('init', ${pixelClient});
    } else {
        (function checkFBLoaded() {
            if (fbq.version > '2.0') {
                try {
                    var state = fbq.getState();
                    var hasPixel = false;
                    for (var itemState of state.pixels) {
                        if (
                            itemState.id === ${pixel} ||
                            itemState.id === ${pixelClient}
                        ) {
                            hasPixel = true;
                        }
                    }
                    if (!hasPixel) {
                        fbq('init', ${pixel});
                        fbq('init', ${pixelClient});
                    }
                } catch (error) {
                    setTimeout(checkFBLoaded, 10);
                }
            } else {
                setTimeout(checkFBLoaded, 10);
            }
        })();
    }
`;
    return (
        <script
            suppressHydrationWarning
            defer
            dangerouslySetInnerHTML={{
                __html: html,
            }}
        />
    );
};

export const FacebookPixelsBody = ({
    pixel,
    pixelClient,
}: FacebookTrackerProps): JSX.Element => {
    return (
        <noscript>
            <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                src={`https://www.facebook.com/tr?id=${pixel}&ev=PageView&noscript=1`}
            />
            <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                src={`https://www.facebook.com/tr?id=${pixelClient}&ev=PageView&noscript=1`}
            />
        </noscript>
    );
};
