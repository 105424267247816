import { TrackingEvent } from '../../types';

interface FacebookEventData {
    eventName: string;
    type: 'standard' | 'custom';
}

export function getFacebookEvent(
    event: TrackingEvent
): FacebookEventData | null {
    let facebookEvent: string | null = null;

    let isStandardEvent = false;

    switch (event) {
        // Page Views
        case 'PageView':
            facebookEvent = 'PageView';
            isStandardEvent = true;
            break;
        case 'ViewProduct':
            facebookEvent = 'ViewContent';
            isStandardEvent = true;
            break;
        case 'PerformSearch':
            facebookEvent = 'Search';
            isStandardEvent = true;
            break;

        // Conversions
        case 'DownloadsClick':
            facebookEvent = 'FileDownload';
            break;
        case 'EnquiryReveal':
            facebookEvent = 'EnquiryReveal';
            break;
        case 'EnquirySubmit':
            facebookEvent = 'EnquirySubmit';
            break;
        case 'Website':
            facebookEvent = 'WebsiteReferral';
            break;
        case 'SaveSubmit':
            facebookEvent = 'Save';
            break;
        case 'PhoneReveal':
            facebookEvent = 'PhoneReveal';
            break;

        case 'BookConsultationReveal':
            facebookEvent = 'BookConsultationReveal';
            break;

        // Ecom
        case 'AddToCart':
            facebookEvent = 'AddToCart';
            isStandardEvent = true;
            break;
        case 'InitiateCheckout':
            facebookEvent = 'InitiateCheckout';
            isStandardEvent = true;
            break;
        case 'Purchase':
            facebookEvent = 'Purchase';
            isStandardEvent = true;
            break;
    }

    if (!facebookEvent) {
        return null;
    }

    return {
        eventName: facebookEvent,
        type: isStandardEvent ? 'standard' : 'custom',
    };
}
