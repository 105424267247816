import { Tracker } from '../types';
import {
    EventData,
    isIgnoredEvent,
    convertLegacyEventName,
    isLibraryPage,
    mergeAdEvents,
} from '../utils/apTrackerHelpers';
import { getPostVars } from '../utils/getPostVars';
import { APEventQueue } from '../apEventQueue';

const ARCHIPRO_LOGGING_URL = '/member/getSitePlaceholder?useAjax=1';

export interface TrackedGuest {
    ID: number;
    __typename: 'Guest';
}

interface ArchProTrackerProps {
    queueDisable?: boolean; // disable queue and send event immediately
}

export function createArchiproTracker(props?: ArchProTrackerProps): Tracker {
    const queue = new APEventQueue(promiseExecutor());

    return {
        name: 'archiproTracker',
        async log(event, opts) {
            if (isIgnoredEvent(event)) {
                return;
            }

            // Don't mutate the original ones, it's shared between trackers
            const url = new URL(opts.url);

            event = convertLegacyEventName(event);

            const postVars = getPostVars(event, {
                url,
                data: opts.data as Record<string, unknown>,
                previousUrl: opts.previousUrl,
            });

            postVars.libraryPage = isLibraryPage(url.pathname) ? 1 : 0;
            if (props?.queueDisable) {
                await sendSingleEvent(postVars, postVars.referer || '');
            } else {
                queue.queue(postVars);
                queue.startTimer();
            }
        },
        release() {
            queue.flush();
        },
    };
}

async function sendSingleEvent(postVars: EventData, referer: string) {
    try {
        const formData = new FormData();
        for (const name in postVars) {
            formData.append(name, postVars[name] as string);
        }
        const response = await fetch(ARCHIPRO_LOGGING_URL, {
            method: 'POST',
            headers: {
                'Cache-Control': 'no-cache',
            },
            body: formData,
            // TODO: (FZ) Is this referrer really needed? since the referer is already included in body?
            referrer: referer,
        });
        if (!response.ok) {
            /**![TECH-DEBT] log-debt replace with proper logging */
            console.error('response code error');
        }
    } catch (e) {
        /**
         * fetch only fails on network errors. Nothing to do here
         */
        console.error(e);
    }
}

function promiseExecutor(): (items: EventData[]) => Promise<void> {
    return async (items: EventData[]) => {
        try {
            const mergedItems = mergeAdEvents(items);
            await fetch(`${ARCHIPRO_LOGGING_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                },
                body: JSON.stringify(mergedItems),
            });
        } catch (_) {
            /**
             * these are connectivity errors, nothing to do.
             * Consider a retry perhaps
             */
            return;
        }
    };
}
