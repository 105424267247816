import { LogOpts } from '../types';

// Mutates
export function trimUndefinedData<T = LogOpts['data']>(data: T): Partial<T> {
    for (const key in data) {
        const value = data[key];

        if (value === undefined) {
            delete data[key];
        }
    }

    return data;
}
