import { TrackingEvent, TrackingEventPostVars } from '../types';

export function getPostVars<TData extends Record<string, unknown>>(
    event: TrackingEvent,
    opts: { url: URL; data?: TData; previousUrl?: URL }
) {
    const { url, previousUrl, data } = opts;

    // New copy of customData
    const postVars = {
        ...(data || {}),
    } as TData & TrackingEventPostVars;

    // document.referrer is out of update after react-router navigation
    // use the location from react instead
    const previousPage = previousUrl?.pathname || document.referrer;

    postVars.url = url.toString();
    postVars.event = event;
    postVars.referer = document.referrer;

    if (previousPage && !isExternalURL(previousPage)) {
        postVars.previousPage = previousPage;
    }

    postVars.created_at = Date.now();

    return postVars;
}

export function isExternalURL(url?: string): boolean {
    if (!url) {
        return false;
    }

    const currentHost = new URL(window.location.href);
    const parsed = new URL(url);
    return currentHost.hostname !== parsed.hostname;
}
