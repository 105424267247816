import { Tracker } from './types';

/**
 * A stub tracker that's here to only collect events.
 * Our use case for this is that `manager` awaits user data
 * before firing events.
 */
export function queueTracker(
    collection?: Parameters<Tracker['log']>[]
): Tracker {
    return {
        name: 'queueTracker',
        // eslint-disable-next-line @typescript-eslint/require-await
        async log(...args) {
            // Collects events if asked
            // This is used to queue up the events while
            // user data is loading
            if (collection) {
                collection.push(args);
            }
        },
        release() {
            // do nothing
        },
    };
}
