import { useEffect, useRef } from 'react';
import {
    createTracker,
    createArchiproTracker,
    createArchiproTrackerV2,
    createArchiproImpressionTracker,
    createGTMTracker,
    useLocationState,
    queueTracker,
    Tracker,
    createGA4Tracker,
} from '../..';
import { createFacebookTracker } from '../../trackers/facebook-tracker/facebookTracker';
import { createHubspotTracker } from '../../trackers/hubspot-tracker/hubspotTracker';
import { Location } from 'history';
import { proxyTracker } from '../../trackers/tracker-proxy';
import { useBeforeUnload } from 'react-router-dom';
import { createPinterestTracker } from '../../trackers/pinterest-tracker/pinterestTracker';

interface TrackerEnv {
    ga4TrackingId: string;
}

type ExtractUserType<T extends (user: never, ...args: never) => Tracker> =
    Parameters<T>[0];

type User = ExtractUserType<typeof createGTMTracker> &
    ExtractUserType<typeof createHubspotTracker>;

/**
 * This is the main entry point to all our tracking needs on both applications.
 * The applications themselves should not be using any of the trackers directly.
 *
 * The tracker waits for `user` to be available to setup the trackers
 */
export const useArchiproTracking = (
    // Required because of conflicting react-router depenedencies.
    // It can be removed once both apps are on the same version of react-router
    // or we switch to pnmp
    location: Location,
    user: User | null,
    trackingEnv: TrackerEnv
): Tracker => {
    const locationState = useLocationState(location);

    const eventCollection = useRef<Parameters<Tracker['log']>[] | undefined>(
        []
    );

    // Disables trackers during SSR run
    // Make the tracker ref to avoid refreshing the state when we switch tracker instances.
    // Use queueTracker first to collect all events before the real tracker is ready.
    const trackerRef = useRef<Tracker>(queueTracker(eventCollection.current));

    // Use a proxy ref to work with `const { log } = useTracker();`
    // Destructuring an object would lost the `this` context.
    // So we need to use a function closure to keep the context here.
    const proxyRef = useRef<Tracker>(proxyTracker(trackerRef));

    // We have some pages are sitting on the old website. We would refresh the whole page when we visit those pages.
    // Some trackers might queue events and send them later. We would lost all events in the queue when we refresh the page.
    // So we need to notify trackers to flush the queue before the page is unloaded.
    useBeforeUnload(() => {
        if (trackerRef.current) {
            trackerRef.current.release();
        }
    });

    useEffect(() => {
        // eventCollection === undefined means that the real trackers have
        // already been set up.
        if (!user || typeof eventCollection.current === 'undefined') return;

        const tracker = createTracker(
            [
                createGTMTracker(user),
                createArchiproTracker(),
                createArchiproTrackerV2(),
                createArchiproImpressionTracker(user),
                createGA4Tracker(user, trackingEnv.ga4TrackingId),
                createFacebookTracker(),
                createPinterestTracker(),
                createHubspotTracker(user),
            ],
            locationState.current
        );

        // Use the real tracker once it is ready.
        trackerRef.current = tracker;

        // Fire all the events that were pushed in before the trackers were ready
        eventCollection.current?.forEach(event => {
            void tracker.log(...event);
        });
        // Don't want to hold it in memory
        // There's no use for them after they're fired
        eventCollection.current = undefined;
        // This warning wasn't fixed.. i'm just doing eslint migrations and don't
        // want to fix it and potentially cause regressions.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return proxyRef.current;
};
