import type { MetaFunction } from '@remix-run/react';
import { getThemeByUrl } from '../../root/util/get-theme-by-url';

export const getThemeMetaTags: MetaFunction = ({ location }) => {
    const theme = getThemeByUrl(location.pathname);

    if (!theme.siteVariables.colorScheme.default?.background) return [];

    return [
        {
            name: 'theme-color',
            content: theme.siteVariables.colorScheme.default.background,
        },
    ];
};
