import { ARCHIPRO_IMPRESSION_TRACKER } from './trackers';
import type { Tracker as TrackerInterface } from './types';
import { trimUndefinedData } from './utils/trimUndefinedData';

export function createTracker(
    trackers: TrackerInterface[],
    // Controlled externally to ensure we have the previousUrl
    location: { url: string; previousUrl?: string }
): TrackerInterface {
    return {
        name: 'baseTracker',
        async log(event, opts) {
            let targetTrackerArray: string[] = [];
            if (typeof opts.targetTracker === 'string') {
                targetTrackerArray = [opts.targetTracker];
            }
            if (Array.isArray(opts.targetTracker)) {
                targetTrackerArray = opts.targetTracker;
            }

            if (
                event === 'Impression' &&
                (!opts.targetTracker ||
                    !targetTrackerArray.includes(ARCHIPRO_IMPRESSION_TRACKER))
            ) {
                throw new Error(
                    'Impressions must be targeted against ARCHIPRO_IMPRESSION_TRACKER'
                );
            }

            if (targetTrackerArray.length > 0) {
                // We separate LoggedEvent and Firehose events into archiproTracker and archiproTrackerV2.
                // There are many legacy events called archiproTracker, so we need to make sure archiproTrackerV2 would be called as well.
                // We can remove this logic once we have migrated away from LoggedEvent.
                if (
                    targetTrackerArray.includes('archiproTracker') &&
                    !targetTrackerArray.includes('archiproTrackerV2')
                ) {
                    targetTrackerArray.push('archiproTrackerV2');
                }
            }

            const availableTrackers = opts.targetTracker
                ? trackers.filter(tracker =>
                      targetTrackerArray.includes(tracker.name)
                  )
                : trackers;

            if (opts.data) {
                trimUndefinedData(opts.data);
            }

            await Promise.allSettled(
                availableTrackers.map(tracker => {
                    return tracker?.log(
                        event,
                        Object.assign(
                            {
                                url: location.url,
                                previousUrl: location.previousUrl,
                            },
                            opts
                        )
                    );
                })
            );
        },

        release() {
            trackers.forEach(tracker => {
                try {
                    tracker.release();
                } catch (_) {
                    // catch exception and avoid breaking the whole process
                }
            });
        },
    };
}
