import { TrackingEvent } from '../../types';

interface PinterestEventData {
    eventName: string;
}

export function getPinterestEvent(
    event: TrackingEvent
): PinterestEventData | null {
    let pinterestEvent: string | null = null;

    switch (event) {
        // Page Views
        case 'PageView':
            pinterestEvent = 'pagevisit'; // Pinterest standard event
            break;

        case 'PerformSearch':
            pinterestEvent = 'search'; // Pinterest standard event
            break;

        case 'AuthSignUpSubmit':
            pinterestEvent = 'signup'; // Pinterest standard event
            break;

        case 'Login':
        case 'AuthSignInSubmit':
            pinterestEvent = 'Login';
            break;

        // Conversions
        case 'DownloadsClick':
            pinterestEvent = 'FileDownload';
            break;
        case 'EnquiryReveal':
            pinterestEvent = 'EnquiryReveal';
            break;
        case 'EnquirySubmit':
            pinterestEvent = 'EnquirySubmit';
            break;
        case 'Website':
            pinterestEvent = 'WebsiteReferral';
            break;
        case 'SaveSubmit':
            pinterestEvent = 'Save';
            break;
        case 'PhoneReveal':
            pinterestEvent = 'PhoneReveal';
            break;

        case 'BookConsultationReveal':
            pinterestEvent = 'BookConsultationReveal';
            break;

        // Ecom
        case 'AddToCart':
            pinterestEvent = 'addtocart'; // Pinterest standard event
            break;
        case 'InitiateCheckout':
            pinterestEvent = 'InitiateCheckout';
            break;
        case 'Purchase':
            pinterestEvent = 'checkout'; // Pinterest standard event
            break;
    }

    if (!pinterestEvent) {
        return null;
    }

    return {
        eventName: pinterestEvent,
    };
}
