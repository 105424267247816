import { Tracker } from '../../types';
import { getGA4EventData, GoogleEventPayload } from './utils';
import { TrackedGuest } from '../archiproTracker';
import { isMember } from '../../utils/isMember';

interface TrackedMember {
    ID: number;
    __typename: 'Me';
    ProfessionalType: string;
    Professionals: Array<{
        Title: string;
        ID: number;
        URLSegment: string;
        IsManufacturer: boolean;
    }>;
    IsArchiproStaff: boolean;
    TrackedGuest?: {
        ID: number;
    } | null;
}

type TrackedUser = TrackedMember | TrackedGuest;

const gtag = (
    command: string,
    action: string, // Can be a target_id, conversion, a property, or otherwise
    options: Record<string, unknown>
) => {
    if (typeof window.gtag === 'function') {
        window.gtag(command, action, options);
    }
};

export function createGA4Tracker(
    user: TrackedUser,
    trackingId: string
): Tracker {
    const curUserID = user.ID.toString();
    // Logged in members are positive id, guest users are negative. Growthbook requires single ID
    const apUserID = user.ID * (isMember<TrackedUser>(user) ? 1 : -1);

    // guest id is used in the GrowthBook auth related experiments
    const guestID =
        user.__typename === 'Me' ? user.TrackedGuest?.ID ?? 0 : user.ID;
    // user properties for custom dimensions migrated from GA3.
    const userProperties = {
        user_type: user.__typename, // dimension1
        professional_type:
            'ProfessionalType' in user && user.ProfessionalType !== undefined
                ? user.ProfessionalType
                : 'N/A', // dimension2
        // dimension3. user_id is a special param in GA4.
        // https://developers.google.com/analytics/devguides/collection/ga4/user-id?client_type=gtag
        user_id: isMember<TrackedUser>(user) ? user.ID : undefined,
        // Stringify because user_ID is stringified in GA4
        tracked_guest_id: !isMember<TrackedUser>(user) ? curUserID : undefined,
        ap_user_id: apUserID,
        is_member:
            'Professionals' in user &&
            (
                Array.isArray(user.Professionals) &&
                user.Professionals.length > 0
            ).toString(), // dimension4
        is_ap_staff:
            'IsArchiproStaff' in user ? user.IsArchiproStaff : undefined,
    };

    gtag('set', 'user_properties', userProperties);

    return {
        name: 'ga4Tracker',
        // eslint-disable-next-line @typescript-eslint/require-await
        async log(event, opts) {
            if (event === 'PageView') {
                // no need pageview anymore. `send_page_view` is on in config by default.
                return;
            }

            const eventData = getGA4EventData(
                event,
                opts.data as unknown as GoogleEventPayload
            );
            const extraData = { ...(opts.ga4Data || {}), guest_id: guestID };

            // GA3 => GA4 mapping rules
            // Action => event_name
            // Category => event_category
            // Label => event_label
            gtag('event', eventData.action, {
                send_to: trackingId, // send to ga directly, not gtm.
                event_category: eventData.category,
                event_label: opts.label || 'unlabeled',
                // for Growthbook.
                // seems not all events can set up user_properties properly. Can't find the reason.
                // copy the ap_user_id to event params for now. ensure we can upload it for every event.
                ap_user_id: apUserID,
                ...extraData,
                // Workaround for sending user properties which could be used by custom dimensions.
                // Ideally, we should use `gtag('set', {'user_properties': userProperties})`.
                // But there are some conflicts between ga and gtm as they share the dataLayer. The `set` method can't work intended.
                // We have to put user properties in `event` method instead.
                // For deduplication purposes, GA4 will collect user properties for the first time or when they are changed.
                // https://support.google.com/analytics/answer/12370404?hl=en
                user_properties: userProperties,
            });
        },
        release() {
            // do nothing
        },
    };
}
